import React, { useState, useEffect } from "react";
import SectionForm from "./SectionForm";

export default function PopupFormulario() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Verificar si el popup ya se mostró
    const popupShown = localStorage.getItem("popupShown");

    if (!popupShown) {
      // Configura un temporizador para mostrar el popup después de 15 segundos
      const timer = setTimeout(() => {
        setShowPopup(true);
        localStorage.setItem("popupShown", "true"); // Marcar como mostrado
      }, 12000); // 12 segundos

      // Limpia el temporizador si el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => setShowPopup(false);

  return (
    <>
      <div
        className={`modal fade ${showPopup ? "show" : ""}`}
        style={{
          display: showPopup ? "block" : "none",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
        role="dialog"
        aria-labelledby="popupFormularioLabel"
        aria-hidden={!showPopup}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <SectionForm />
            </div>
          </div>
        </div>
      </div>
      {/* Opcional: Agregar un overlay si lo deseas */}
      {showPopup && <div className="modal-backdrop fade show"></div>}
    </>
  );
}
