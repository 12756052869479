import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/pages/Layout/Layout";
import Inicio from "./components/pages/Inicio/Inicio";
import Modelo from "./components/pages/Modelo/Modelo";
import './design/css/bootstrap.min.css';
import './design/css/styles.css';
import Contacto from "./components/pages/Contacto/Contacto";
import PopupFormulario from "./components/pagina/PopupFormulario";

function App() {
  return (
    <>
     <Routes>
      <Route path="/" element={<Layout />} >
      <Route path="/" element={<Inicio />} />
      <Route path="modelo/:modelo" element={<Modelo />} />
      <Route path="contacto" element={<Contacto />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
     </Routes>
     < PopupFormulario/>
    </>
    
  );
}

export default App;
