import { BaseUrl } from "../Helpers/ConsData"
export default function Section3(){
    return(
        <>
        <section className="page-section-xxxl my-5 Poppins-ExtraBold " style={{ backgroundImage: `url('${BaseUrl}Secciones/banner_2.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div className="row pt-5 pl-5">
                <div className="col-md-4 h1 text-uppercase">
                Belleza capital
                </div>
            </div>
            <div className="row pl-5">
                <div className="col-md-4 h3 text-danger text-center">
                REDEFINIDA
                </div>
            </div>
            
        </section>
        </>
    )
}