import { datafooter, logoSika2 } from "../Helpers/ConsData"
import { logoBaic } from "../Helpers/ConsData"
export default function Footer(){
    return(
        <>
        <section className="text-white Poppins-Regular" style={{ backgroundColor: `#26292d` }}>
      
        
        
        <div className="row border-bottom border-secondary">
          <div className="col-12 d-flex align-items-center justify-content-between">
           
            <img src={logoBaic} alt="Logo" className="img-fluid m-4 logo-responsive" />
            
            <img src={logoSika2} alt="Logo" className="m-4 img-fluid" style={{ height: "40px" }} />
          </div>
        </div>
        
       
        
        {/* 5 Columnas con títulos y opciones */}
        <div className="row justify-content-center my-5 margin-responsive">
        {datafooter.sort((a, b) => a.columna - b.columna).map((col, index) => (
            <div className="col-md-2" key={index}>
              <h5 className="font-weight-bold title-border-left">{col.title}</h5>
              
              <ul className="list-unstyled my-3">
                {col.opciones.map((opcion, index) => (
                  <li key={index} className="py-2">
                    {/* Si la URL está vacía no muestra la etiqueta */}
                    { opcion.url ? (
                      <a href={opcion.url} className="text-decoration-none option-footer">
                        {opcion.text}
                      </a>

                    ):(
                      <p> {opcion.text} </p>
                    )}
                    <p>{opcion.detalle}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        
        
           
          
        
        {/* Copyright */}
        <div className="row justify-content-center border-top border-secondary">
          <div className="col-mb-10 text-center my-3">
            <p className="m-3">&copy; {new Date().getFullYear()} BAIC Guatemala</p>
          </div>
        </div>
        </section>
        </>
    )
}