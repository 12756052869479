import { BaseUrl } from "../Helpers/ConsData";

export default function Sectionmap(){
    return(
        <>
        <section className="page-section-xl mt-5 Poppins-ExtraBold " style={{ backgroundColor: `#0f141d` }}>
            <div className="row justify-content-center text-center">
                <div className="col-md-12 text-white h1 mt-5">
                BAIC EN EL MUNDO
                </div>
            </div>
            <div className="row justify-content-center text-center">
                <div className="col-md-12 text-white h5">
                Encuentra BAIC en tu país
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src={`${BaseUrl}Secciones/01_Mapa_presencia_V2.png`} className="img-fluid"/>
                </div>
            </div>
        </section>
        </>
    )
}